import React from 'react';
import Layout from '../components/Layout';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <p class="title has-text-centered">Contact Us</p>
          <div className="tile is-ancestor" style={{ marginTop: '30px' }}>
            <div className="content tile is-parent is-6">
              <article className="tile is-child is-12">
                <div
                  style={{
                    maxWidth: '400px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '30px'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <i
                      class="fas fa-map-marked-alt"
                      style={{ marginRight: '20px' }}
                    />
                    <p>
                      Jade Medical Clinic Pte Ltd <br />8 Eu Tong Sen Street{' '}
                      <br />
                      #13-99 The Central Office Tower 1 <br />
                      Singapore 059818
                    </p>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <i
                      class="fas fa-phone-alt"
                      style={{ marginRight: '20px' }}
                    />
                    <p>
                      <a href="tel:+6562259813">62259813</a>
                    </p>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <i class="fas fa-fax" style={{ marginRight: '20px' }} />
                    <p>62259541</p>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <i
                      class="fas fa-envelope"
                      style={{ marginRight: '20px' }}
                    />
                    <p>
                      <a href="mailto:reception@jademedical.com.sg">
                        reception@jademedical.com.sg
                      </a>{' '}
                      <br />
                      <a href="mailto:doctor@jademedical.com.sg">
                        doctor@jademedical.com.sg
                      </a>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="tile is-parent is-6">
              <article className="tile is-child is-10">
                <div class="mapouter">
                  <div
                    class="gmap_canvas"
                    style={{
                      position: 'relative',
                      textAlign: 'right',
                      height: '500px',
                      width: '100%'
                    }}
                  >
                    <iframe
                      width="100%"
                      height="500"
                      style={{
                        overflow: 'hidden',
                        background: 'none',
                        height: '500px',
                        width: '100%'
                      }}
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=Jade%20Medical%20Clinic&t=&z=17&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
